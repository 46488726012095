<template>
  <b-modal
    id="cms-manage-layout"
    :title="modalTitle"
    no-close-on-backdrop
    size="lg"
    @ok="onOk"
    @close="onCloseEvent"
    @cancel="onCloseEvent"
  >
    <ValidationObserver
      ref="cmsForm"
      v-slot="{ handleSubmit }"
    >
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <b-form-group label="Use image">
          <b-form-checkbox
            v-model="isUseImageAsTitle"
            name="check-button"
            switch
          >
            Use Image as title
          </b-form-checkbox>
        </b-form-group>
        <ValidationProvider
          v-if="!isUseImageAsTitle"
          v-slot="{ errors }"
          name="title"
          :rules="{
            required: false,
          }"
        >
          <b-form-group label="Title">
            <b-form-input
              id="title"
              v-model="layoutForm.title"
              :state="errors[0] ? false : null"
            ></b-form-input>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-else
          v-slot="{ errors }"
          name="image_src"
          :rules="{
            required: false,
          }"
        >
          <b-form-group label="Title image">
            <CmsFileSelect
              :value="layoutForm.imageSrc"
              :state="errors[0] ? false : null"
              @update="(value) => (layoutForm.imageSrc = value || '')"
            />
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          name="title_type"
          :rules="{
            required: true,
          }"
        >
          <b-form-group label="Title Type">
            <b-form-select
              id="title_type"
              v-model="layoutForm.titleType"
              :state="errors[0] ? false : null"
            >
              <b-form-select-option
                v-for="(item, index) in cmsEnumTypes.titles"
                :key="index"
                :value="item"
              >{{ item }}</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          name="is_slider"
          :rules="{
            required: true,
          }"
        >
          <b-form-group label="Use slider (all card settings inside row will be ignore) recommnded use square image (1:1) in row ">
            <b-form-checkbox
              v-model="layoutForm.isSlider"
              name="is_slider"
              switch
              :state="errors[0] ? false : null"
            >
            </b-form-checkbox>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          v-if="layoutForm.isSlider"
          v-slot="{ errors }"
          name="slide_per_view"
          :rules="{
            required: true,
          }"
        >
          <b-form-group label="Slide per views">
            <b-form-input
              id="slide_per_view"
              v-model="layoutForm.slidesPerView"
              :state="errors[0] ? false : null"
            ></b-form-input>
          </b-form-group>
        </ValidationProvider>
        <b-form-group label="Background type">
          <b-form-select v-model="layoutForm.backgroundType">
            <b-form-select-option
              v-for="item in backgroundTypes"
              :key="item"
              :value="item"
            >{{ item }}</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group
          v-if="layoutForm.backgroundType !== 'NO_BG'"
          label="Background color"
        >
          <ColorPicker
            :color="layoutForm.backgroundColor"
            :sucker-hide="true"
            class="mb-2"
            style="width: 220px"
            @changeColor="onChangeColor"
          />
          <b-form-input v-model="layoutForm.backgroundColor"></b-form-input>
          <small v-if="layoutForm.backgroundType === 'BG_COLOR_BLUR'">When background type `BG_COLOR_BLUR` you should select `Alpha
            channel` less than 1</small>
        </b-form-group>

        <div v-if="layoutForm.titleType === 'Title_2'">
          <b-form-group label="Title Font Size">
            <b-form-input
              id="titleFontSize"
              v-model="layoutForm.titleFontSize"
              placeholder="16px, 1rem"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Title Color">
            <ColorPicker
              :color="layoutForm.titleColor"
              :sucker-hide="true"
              class="mb-2"
              style="width: 220px"
              @changeColor="onChangeTitleColor"
            />
            <b-form-input v-model="layoutForm.titleColor"></b-form-input>
          </b-form-group>
          <b-form-group label="Title Color">
            <ColorPicker
              :color="layoutForm.titleBackgroundColor"
              :sucker-hide="true"
              class="mb-2"
              style="width: 220px"
              @changeColor="onChangeTitleBackgroundColor"
            />
            <b-form-input v-model="layoutForm.titleBackgroundColor"></b-form-input>
          </b-form-group>
        </div>

        <ValidationProvider
          v-slot="{ errors }"
          name="position"
          :rules="{
            required: true,
          }"
        >
          <b-form-group label="Position">
            <b-form-input
              id="position"
              v-model="layoutForm.position"
              :state="errors[0] ? false : null"
            ></b-form-input>
          </b-form-group>
        </ValidationProvider>
      </b-form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import ColorPicker from '@caohenghu/vue-colorpicker'

export default {
  components: {
    CmsFileSelect: () => import('../file-select.vue'),
    ColorPicker,
  },
  props: {
    layoutData: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isUseImageAsTitle: true,
      layoutForm: {
        position: 0,
        title: '',
        imageSrc: '',
        titleType: this.cmsEnumTypes?.titles?.[0],
        isSlider: false,
        slidesPerView: 2,
        backgroundType: 'NO_BG',
        backgroundColor: '',
        titleColor: '',
        titleFontSize: '',
        titleBackgroundColor: '',
      },
      backgroundTypes: ['NO_BG', 'BG_COLOR', 'BG_COLOR_BLUR'],
    }
  },
  computed: {
    ...mapState({
      cmsEnumTypes: (state) => state.cms.cmsEnumTypes,
    }),
    ...mapGetters(['siteId']),
    modalTitle() {
      if (this.layoutForm.id) {
        return 'Update layout'
      }
      return 'Create layout'
    },
  },
  watch: {
    layoutData(val) {
      if (val) {
        this.layoutForm = JSON.parse(JSON.stringify(val))
      } else {
        this.layoutForm = {
          position: 0,
          title: '',
          imageSrc: '',
          titleType: this.cmsEnumTypes?.titles?.[0],
          isSlider: false,
          slidesPerView: 2,
          backgroundType: 'NO_BG',
          backgroundColor: '',
          titleColor: '',
          titleFontSize: '',
          titleBackgroundColor: '',
        }
      }
    },
  },
  methods: {
    ...mapActions(['createCmsLayout', 'updateCmsLayout']),
    async onOk(e) {
      e.preventDefault()
      const valid = await this.$refs.cmsForm.validate()
      if (valid) {
        this.onSubmit(() => this.$bvModal.hide('cms-manage-layout'))
        this.onCloseEvent()
      }
    },
    async onSubmit(cb) {
      if (this.layoutForm.id) {
        // update
        await this.updateCmsLayout({
          siteId: this.siteId,
          layoutId: this.layoutForm.id,
          data: this.layoutForm,
        })
      } else {
        await this.createCmsLayout({
          siteId: this.siteId,
          data: this.layoutForm,
        })
      }

      cb()
    },
    onCloseEvent() {
      this.$emit('updated')
    },
    onChangeColor({ rgba: { r, g, b, a }, hex }) {
      if (this.layoutForm.backgroundType === 'BG_COLOR') {
        this.layoutForm.backgroundColor = hex
      } else if (this.layoutForm.backgroundType === 'BG_COLOR_BLUR') {
        this.layoutForm.backgroundColor = `rgba(${r}, ${g}, ${b}, ${a})`
      }
    },
    onChangeTitleColor({ hex }) {
      this.layoutForm.titleColor = hex
    },
    onChangeTitleBackgroundColor({ rgba: { r, g, b, a } }) {
      this.layoutForm.titleBackgroundColor = `rgba(${r}, ${g}, ${b}, ${a})`
    },
  },
}
</script>
